import * as React from "react";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import { DefaultButton } from "@fluentui/react";
import { autoValidVinSearchStatus, getBookings } from "../../../state/action";
import { RootState } from "../../../state";
import { CreateTicketAutoValid, GetTicketUpdate, setVinNumber } from "../../../state/autoValid/action";

interface Props
{

}

const canUseAutoValid = (orderType: AssignmentType) =>
{
    return orderType === "REPLACE_FRONT"|| orderType === "REPLACE_REAR" || orderType === "REPLACE_ROOF";
}

const AutoValidVinSearch: React.FunctionComponent<Props & StateProps> = props => {

    React.useEffect(() => {
        if(props.order.car && props.order.car.vehicleIdentificationNumber){
            setVinNumber(props.order.car.vehicleIdentificationNumber);
        }
    },[]);
    React.useEffect(() =>
    {
        if(props.status === "STARTED" && props.order.additionalData.autoValidTicketId)
        {
            if(props.order.car && props.order.car.vehicleIdentificationNumber)
            {
                props.GetTicketUpdate(props.order.additionalData.autoValidTicketId, props.order.additionalData.orderType, props.order.car.vehicleIdentificationNumber);
            }
         
        }
     
       
    },[props.status, props.order.additionalData.autoValidTicketId])
    const onVINSearchClick = () =>
    {
        if(props.order.car && props.order.car.vehicleIdentificationNumber)
        {
            props.createAutoValidTicket(props.order.car.vehicleIdentificationNumber, props.order.additionalData.orderType);
        }
    }
    if(props.status === undefined && canUseAutoValid(props.order.additionalData.orderType))
    {
        return <DefaultButton onClick={onVINSearchClick}>
            Auto Valid VIN opslag
        </DefaultButton>;
    }
    return <div>
        {props.status}
    </div>
   
};

const mapStateToProps = (state: RootState) => {
    return {
        order: getBookings(state),
        status: autoValidVinSearchStatus(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    createAutoValidTicket: (vin: string, orderType: AssignmentType) => dispatch<any>(CreateTicketAutoValid(vin, orderType)),
    GetTicketUpdate: (ticketId: string, orderType: AssignmentType, vin: string) => dispatch<any>(GetTicketUpdate(ticketId, orderType, vin)),
    setVinNumber: (vin: string) => dispatch<any>(setVinNumber(vin))
   
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(AutoValidVinSearch);