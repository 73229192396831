import { Checkbox, Dropdown, IDropdownOption, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";

interface Props {
    order: Order;
    updateOrder: (order: Order) => void;
}
const OEWindow = (props: Props) => {
    const [isChecked, setIsChecked] = useState<boolean>(props.order.orderProperties && props.order.orderProperties.oeWindow ? true : false);
   

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsChecked(checked);
        props.updateOrder({...props.order, orderProperties: {...props.order.orderProperties, oeWindow: undefined}});
    } 
    const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        let oeWindow: string | undefined = option.key as string;
        if (!oeWindow)
        {
            oeWindow = undefined;
        }
       props.updateOrder({...props.order, orderProperties: {...props.order.orderProperties, oeWindow: oeWindow}});
    };
    const options: IDropdownOption[] = [
        {key: "1", text: "Rude kan kun skaffes OE"},
        {key: "2", text: "Kalibreringsproblemer med eftermarkedsrude"},
        {key: "3", text: "Ønske fra kunde"}
    ];

    return (
        <Stack  style={{maxWidth:"450px"}} horizontal grow={2}>
            <StackItem grow={0.5}>
                <Checkbox boxSide={"end"} checked={isChecked} onChange={handleCheckboxChange} label="OE rude?" />
             
            </StackItem>
            <StackItem grow={1.5}>
            {isChecked && <Dropdown style={{maxWidth:"350px"}} onChange={handleDropdownChange} selectedKey={props.order.orderProperties && props.order.orderProperties.oeWindow} options={options} /> }
            </StackItem>
        </Stack>
    );
};
export default OEWindow;