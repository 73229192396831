import { Action, Dispatch } from "redux";
import API from "../../api";
import { UpdateProductListAction, isProductRequestProcessing, productSignalController } from "../product/action";

export interface SetAutoValidTicketId extends Action {
    type: "AUTO_VALID_TICKETID";
    ticketId: string;
}
export interface AutoValidTicketUpdate extends Action {
    type: "AUTO_VALID_TICKET_UPDATE";
    status: "ENDED" | "NOTREADY";
}
export const CreateTicketAutoValid = (vin: string, orderType: AssignmentType) => async (dispatch: Dispatch<any>) => {
    if (isProductRequestProcessing) {
        productSignalController.abort();
    }
    const result = await API.AutoValidVinSearch.CreateTicketAutoValid(vin, orderType);
    if (typeof result === 'number') {
        dispatch({ type: "AUTO_VALID_TICKETID", ticketId: result });
    }
    else {
        dispatch({ type: "AUTO_VALID_TICKET_UPDATE", status: "ENDED" });
        result.forEach(p => {
            p.checked = true;
            p.items.forEach(i => (i.amount = 1));
        });
        dispatch({ type: "UPDATE_PRODUCT_LIST", productGroups: result, merge: false } as UpdateProductListAction);
        setTimeout(() => {
            dispatch({ type: "AUTO_VALID_TICKET_UPDATE", status: undefined });
        }, 5000);

    }
};

let vinNumber:string | undefined = undefined

export const setVinNumber = (vin: string) => {
    vinNumber = vin;
}

export const GetTicketUpdate = (ticketId: string, orderType: AssignmentType, vin: string) => async (dispatch: Dispatch<any>) => {
    if(vinNumber === vin)
    {
        setTimeout(async () => {
            const result = await API.AutoValidVinSearch.GetTicketUpdate(ticketId, orderType);
            if (typeof result === 'string') {
                dispatch({ type: "AUTO_VALID_TICKET_UPDATE", status: "NOTREADY" });
            }
            else {
                dispatch({ type: "AUTO_VALID_TICKET_UPDATE", status: "ENDED" });
                if(vin === result.vin)
                {
                    result.products.forEach(p => {
                        p.checked = true;
                        p.items.forEach(i => (i.amount = 1));
                    });
                    dispatch({ type: "UPDATE_PRODUCT_LIST", productGroups: result.products, merge: false } as UpdateProductListAction);
                }
                setTimeout(() => {
                    dispatch({ type: "AUTO_VALID_TICKET_UPDATE", status: undefined });
                }, 5000);
            }
            dispatch(GetTicketUpdate(ticketId, orderType, vin));
        }, 5000);
       
    }
    else
    {
        dispatch({ type: "AUTO_VALID_TICKET_UPDATE", status: undefined });
    }
   
};