import * as order from "./order";
import * as ui from "./ui";
import * as car from "./car";
import * as user from "./user";
import { RootState } from ".";
import * as product from "./product";
import * as worksheet from "./worksheet";
import * as transfer from "./transfer";
import * as oldProducts from "./old_products";
import * as zipCodeDepartments from "./zipCode";
import * as department from "./department";
import * as calendar from "./calendar";
import * as debitorRuleSets from "./debitorRuleSets";
import * as timeslot from "./timeslot";
import * as capacityOptimizationResult from "./capacityOptimization";
export const getBookings = (state: RootState) => order.getOrder(state.order);

export const getCustomer = (state: RootState) => order.getCustomer(state.order);

export const getDebitor = (state: RootState) => order.getDebitor(state.order);

export const getStatusMessages = (state: RootState) =>
    ui.getStatusMessages(state.ui);

export const getExceptions = (state: RootState) => ui.getExceptions(state.ui);

export const getCarManufactures = (state: RootState) =>
    car.getCarManufactures(state.car);

export const getCarManufactureModels = (state: RootState) =>
    car.getCarManufactureModels(state.car);

export const getCar = (state: RootState) => order.getCar(state.order);

export const getTimeAndPlace = (state: RootState) =>
    order.getTimeAndPlace(state.order);

export const getOrder = (state: RootState) => order.getOrder(state.order);

export const getOrders = (state: RootState) =>
    worksheet.getOrders(state.worksheet);

export const getProductList = (state: RootState) =>
    product.getProducts(state.product);
export const getInstructionUrl = (state: RootState) =>
    product.getInstructionUrl(state.product);

export const getInsurers = (state: RootState) => ui.getInsurers(state.ui);

export const getLeasers = (state: RootState) => ui.getLeasers(state.ui);

export const getProductSearchQuery = (state: RootState) =>
    ui.getProductSearchQuery(state.ui);

export const autoValidVinSearchStatus = (state: RootState) =>
    ui.autoValidVinSearchStatus(state.ui);

export const getUserInformation = (state: RootState) =>
    user.getUserInformation(state.user);

export const getPages = (state: RootState) =>
    worksheet.getPages(state.worksheet);

export const getOperators = (state: RootState) => user.getOperators(state.user);

export const getUsers = (state: RootState) => user.getUsers(state.user);

export const getTransfers = (state: RootState) =>
    transfer.getTransfers(state.transfer);

export const getWhoIsPaying = (state: RootState) =>
    order.getWhoIsPaying(state.order);

export const getDebitors = (state: RootState) => ui.getDebitors(state.ui);

export const getBinList = (state: RootState) =>
    transfer.getBinList(state.transfer);

export const getOldProductsList = (state: RootState) =>
    oldProducts.getOldProducts(state.oldProducts);

export const getZipCodeDepartmentAvailabilityList = (state: RootState) =>
    zipCodeDepartments.getZipCodeDepartmentAvailabilityList(state.zipCode);

export const getDepartments = (state: RootState) =>
    department.getDepartments(state.department);

export const getAlreadyUsedTimeslots = (state: RootState) =>
    department.getAlreadyUsedTimeslots(state.department);

export const getAdditionalData = (state: RootState) =>
    order.getAdditionalData(state.order);

export const getCalendarDefaultEvent = (state: RootState) =>
    calendar.getEvent(state.calendar);

export const getCurrentCalendarBookingId = (state: RootState) =>
    calendar.getCurrentCalendarBookingId(state.calendar);

export const getDebitorRuleSets = (state: RootState) =>
    debitorRuleSets.GetDebitorRuleSets(state.debitorRuleSets);
    
export const getAvailableDepartmentBooking = (state: RootState) =>
    timeslot.getAvailableDepartmentBooking(state.timeslot);

export const getZipCodes = (state: RootState) =>
    timeslot.getZipCodes(state.timeslot);

export const getCalendarOperator = (state: RootState) => 
    ui.getCalendarOperator(state.ui);

export const getSelectList = (state: RootState, area: SelectListArea) => 
    ui.getSelectList(state.ui, area);

export const getCapacityOptimizationResult = (state: RootState) => 
    capacityOptimizationResult.getCapacityOptimizationResult(state.capacityOptimization);

export const getDeliveryForcast = (state: RootState) => 
    capacityOptimizationResult.getDeliveryForcast(state.capacityOptimization);
export const getDeliveryForcastLookupDate = (state: RootState) => 
    capacityOptimizationResult.getDeliveryForcastLookupDate(state.capacityOptimization);
export const getDeliveryForcastStoneToReplaceDate = (state: RootState) => 
    capacityOptimizationResult.getDeliveryForcastStoneToReplaceDate(state.capacityOptimization);

export const getDepartmentResourceRelation = (state: RootState) => 
    department.getDepartmentResourceRelation(state.department);