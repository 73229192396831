import BaseApi from "./baseApi";

export default class DeliveryTimeApi extends BaseApi {
    async CreateTicketAutoValid(vin:string, orderType: AssignmentType): Promise<ProductGroup[] | number> {
        const url = `/api/vin/CreateTicketAutoValid/${vin}/${orderType}`;
        return await this.fetchGet(url);
    }

     async GetTicketUpdate(ticketId:string, orderType: AssignmentType): Promise<{products: ProductGroup[], vin: string} | string> {
        const url = `/api/vin/GetTicketUpdate/${ticketId}/${orderType}`;
        return await this.fetchGet(url);
    }
}
